import * as Sentry from '@sentry/browser';

/**
 * logError
 *
 * @param {*} err - error response
 * @param {*} context - function name and file location
 *
 * Capture an error response and logs it to sentry.io
 * Example: logError(error, 'FunctionName - /path/to/file/name.js');
 * Filled Example: logError(error, 'AddPaymentMethod - /components/Payment/CardPayment/AddCard.js');
 *
 */
export const logError = (err, context) => {
  Sentry.captureException(err, {
    tags: {
      error_location: context, // custom tag
    },
  });
};
