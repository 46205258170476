import useSWR from 'swr';
import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import axiosWithMjrAccessToken from 'utils/axiosWithMjrAccessToken';
import axios from 'axios';

export const useMjrApiUser = () => {
  const { user: session } = useUser();
  const router = useRouter();

  return useSWR(
    session && router.pathname !== '/create-account' ? `${process.env.NEXT_PUBLIC_PROXY_MJR_API_URL}/api/customer/get` : null,
    (url) => axiosWithMjrAccessToken.get(url).then((res) => res.data),
  );
};

export const useContent = () => useSWR(
  '/api/get-content',
  (url) => axios.get(url).then((res) => res.data),
);
