const axios = require('axios');

const axiosWithMjrAccessToken = axios.create();

// Request interceptor for API calls
axiosWithMjrAccessToken.interceptors.request.use(
  async (config) => {
    const accessTokenResult = await axios.get('/api/get-access-token');
    config.headers.Authorization = `Bearer ${accessTokenResult.data.accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default axiosWithMjrAccessToken;
